define('checkin/components/allocation-button/component', ['exports', 'checkin/validations/allocation'], function (exports, _allocation) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({ AllocationValidations: _allocation.default }, {
		showEditor: false,

		click: function click() {
			this.set('showEditor', !this.get('showEditor'));
		}
	});
});