define('checkin/models/core', ['exports', 'ember-data', 'lodash', 'checkin/models/imageable'], function (exports, _emberData, _lodash, _imageable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = _imageable.default.extend({
    category: belongsTo('category'),
    application: attr('map', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    cacNum: attr('string'),
    keyedOemNums: attr('map', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    unkeyedOemNums: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    oemProperties: attr('map', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    keyedInterchanges: attr('map', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    unkeyedInterchanges: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    priceListKey: attr('string'),

    attachments: hasMany('attachment'),

    stockpiles: hasMany('stockpile'),
    allocation: belongsTo('allocation'),

    interchanges: computed('keyedInterchanges', 'unkeyedInterchanges', function () {
      return _lodash.default.values(get(this, 'keyedInterchanges')).concat(get(this, 'unkeyedInterchanges'));
    }),

    hollanderNums: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    purchasePrice: attr('number'),
    tag: attr('string'),

    imageUrls: attr('list', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});