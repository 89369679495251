define('checkin/components/core-peek/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    store: Ember.inject.service(),

    classNames: ['core-peek'],
    classNameBindings: ['isActive:-is-active', 'tagged:core-peek--tagged', 'tagged'],

    core: null,
    isActive: false,

    count: 1,

    tagged: computed('core.tag', function () {
      return get(this, 'core.tag') ? 'core-peek--tagged-' + get(this, 'core.tag') : '';
    }),

    coreStockpiledCount: computed('core.stockpiles.@each.count', function () {
      return get(this, 'core.stockpiles').mapBy('count').reduce(function (acc, value) {
        return acc + value;
      }, 0);
    }),

    // keyboardActivated: alias('isActive'),
    keyboardActivated: false

    // onEnter: on(keyDown('Enter'), function() {
    //   if (get(this, 'isActive')) {
    //     this.checkIn(get(this, 'core')); // TODO - variants
    //   }
    // })
  });
});