define('checkin/models/shelf', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  var keys = Object.keys;
  var schema = exports.schema = {
    num: attr('string'),
    capacity: attr('number'),

    categories: hasMany('category', { async: false })
  };

  exports.default = Model.extend(schema, { keys: keys(schema) });
});