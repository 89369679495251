define('checkin/models/checkin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend((_Model$extend = {
    batch: belongsTo('batch'),
    isScrap: attr('boolean'),

    core: belongsTo('core'),
    variant: belongsTo('variant'),

    affectStockpile: attr('boolean', { writeOnly: true }),
    affectedStockpile: belongsTo('stockpile', { readOnly: true }),

    affectAllocation: attr('boolean', { writeOnly: true }),
    affectedAllocation: belongsTo('allocation', { readOnly: true }),

    count: attr('number', { defaultValue: 1 }),
    comment: attr('string'),
    desc: attr('string')

  }, _defineProperty(_Model$extend, 'desc', attr('string')), _defineProperty(_Model$extend, 'userId', attr('string', { readOnly: true })), _defineProperty(_Model$extend, 'createdAt', attr('date', { readOnly: true })), _Model$extend));
});