define('checkin/routes/search/index', ['exports', 'checkin/services/pager'], function (exports, _pager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      RSVP = Ember.RSVP,
      inject = Ember.inject,
      get = Ember.get,
      set = Ember.set,
      merge = Ember.merge;
  exports.default = Route.extend({
    notify: inject.service(),
    undo: inject.service(),
    shelves: inject.service(),

    queryParams: {
      keywords: {
        refreshModel: true
      },

      categoryName: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var store = this.store;

      var query = params.keywords ? store.query('core', { include: 'allocation,stockpiles', filter: params }) : RSVP.resolve([]);

      var pager = _pager.default.create({ store: store, firstPage: query });

      return RSVP.hash(merge(this.modelFor('search'), { pager: pager.resolvePages() }));
    },
    saveBatch: function saveBatch(changeset, batch) {
      var _this = this;

      return changeset.validate().then(function () {
        if (get(changeset, 'isValid')) {
          return changeset.save().then(function () {
            get(_this, 'notify').success("Batch created");
            return batch;
          }).catch(function (reason) {
            get(batch, 'errors').forEach(function (_ref) {
              var attribute = _ref.attribute,
                  message = _ref.message;

              changeset.addError(attribute, message);
            });

            throw reason;
          });
        } else {
          return RSVP.reject();
        }
      });
    },
    createCheckin: function createCheckin(batch, count, core, variant, comment, affectInventory) {
      var _this2 = this;

      var checkin = this.store.createRecord('checkin', { batch: batch, count: count, core: core, variant: variant, comment: comment, affectStockpile: affectInventory, affectAllocation: affectInventory });

      return checkin.save().then(function (checkin) {
        get(_this2, 'undo').register(_this2.destroyCheckin.bind(_this2, get(checkin, 'id')), "Checkin created");

        return checkin;
      }).catch(function (reason) {
        if (reason.errors) {
          get(_this2, 'notify').error(get(reason.errors, 'firstObject.detail'));
        }

        throw reason;
      });
    },
    destroyCheckin: function destroyCheckin(id) {
      var _this3 = this;

      var checkin = this.store.peekRecord('checkin', id);
      var stockpile = get(checkin, 'affectedStockpile');

      return checkin.destroyRecord().then(function () {
        return stockpile;
      }).then(function (stockpile) {
        return stockpile.reload();
      }).finally(function () {
        get(_this3, 'notify').success("Checkin deleted");
      }).catch(function (reason) {
        if (reason.errors) {
          get(_this3, 'notify').error(get(reason.errors, 'firstObject.detail'));
        }

        throw reason;
      });
    },
    allocate: function allocate(allocation, changeset, snapshot) {
      var _this4 = this;

      var allowUndo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      return changeset.validate().then(function () {
        if (get(changeset, 'isValid')) {
          return changeset.save().then(function () {
            if (allowUndo) {
              get(_this4, 'undo').register(function () {
                changeset.restore(snapshot);
                return _this4.allocate(allocation, changeset, null, false);
              }, "Inventory updated");
            } else {
              get(_this4, 'notify').success("Inventory updated");
            }

            return allocation;
          }).catch(function (reason) {
            get(allocation, 'errors').forEach(function (_ref2) {
              var attribute = _ref2.attribute,
                  message = _ref2.message;

              changeset.addError(attribute, message);
            });

            throw reason;
          });
        } else {
          return RSVP.reject();
        }
      });
    },
    destroyAllocation: function destroyAllocation(id) {
      var _this5 = this;

      return this.store.peekRecord('allocation', id).destroyRecord().then(function () {
        get(_this5, 'notify').success("Allocation deleted");
      });
    },
    audit: function audit(count, core, variant) {
      var _this6 = this;

      var store = this.store;

      return store.createRecord('audit', { count: count, core: core, variant: variant }).save().then(function (audit) {
        get(_this6, 'notify').success("Stock updated");
        store.unloadRecord(audit);
      }).catch(function (reason) {
        if (reason.errors) {
          get(_this6, 'notify').error(get(reason.errors, 'firstObject.detail'));
        }

        throw reason;
      });
    },
    createShelf: function createShelf(changeset, shelf) {
      var _this7 = this;

      return changeset.validate().then(function () {
        if (get(changeset, 'isValid')) {
          return changeset.save().then(function () {
            get(_this7, 'shelves').registerNum(get(shelf, 'num'));

            return shelf;
          }).catch(function (reason) {
            get(shelf, 'errors').forEach(function (_ref3) {
              var attribute = _ref3.attribute,
                  message = _ref3.message;

              changeset.addError(attribute, message);
            });

            throw reason;
          });
        } else {
          return RSVP.reject();
        }
      });
    },
    createLabel: function createLabel(printerIdx, core) {
      var _this8 = this;

      var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

      var label = this.store.createRecord('label', { printerIdx: printerIdx, core: core, size: size });

      return label.save().then(function (label) {
        get(_this8, 'notify').info('Printing ' + size + ' labels for core ' + get(core, 'cacNum'));
        return label;
      }).catch(function (reason) {
        if (reason.errors) {
          get(_this8, 'notify').error(get(reason.errors, 'firstObject.detail'));
        }

        throw reason;
      });
    },


    actions: {
      notify: function notify(message) {
        get(this, 'notify').info(message);
      },
      loading: function loading(transition) {
        var controller = this.controllerFor(this.routeName);
        set(controller, 'isLoading', true);

        transition.promise.then(function () {
          set(controller, 'isLoading', false);
        });
      },
      saveBatch: function saveBatch(changeset, batch) {
        return this.saveBatch(changeset, batch);
      },
      checkIn: function checkIn(batch, count, core, variant, comment, affectInventory) {
        return this.createCheckin(batch, count, core, variant, comment, affectInventory);
      },
      createLabel: function createLabel(printerIdx, core, size) {
        return this.createLabel(printerIdx, core, size);
      },
      allocate: function allocate(allocation, changeset, snapshot) {
        return this.allocate(allocation, changeset, snapshot);
      },
      audit: function audit(count, core, variant) {
        return this.audit(count, core, variant);
      },
      createShelf: function createShelf(changeset, shelf) {
        return this.createShelf(changeset, shelf);
      }
    }
  });
});